.privacy-policy{
  background-color:#239AF5;
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  align-items: start;
  justify-content: center;
  text-align: start;
}

.privacy-content{
  width: 700px;
  margin: 16px;
  padding: 32px;
  background-color: white;
  border-radius: 2em;
}

.privacy-text{
  text-align: start;
}

.header-container {
  display:flex;
  flex-direction: column;
  align-items: start;
  height: auto;
}

.title-container{
  margin: 12px 0 ;
  padding:0;
}

.img-container{
  height: 60px;
}

.img-wrapper{
  object-fit: contain;
  height: 100%;
}

@media (max-width:700px){
  .privacy-content{
    width: 100%;
    margin: 0px;
    border-radius: 0px;
  }
}