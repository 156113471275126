.bottom-section{
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

.signup-bottom-card{
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items:center;
  background-color: #239AF5;
  color: white;
  width: fit-content;
  padding: 0 2rem;
  margin: 0 1rem;
}

.bottom-title{
  flex:1;
  vertical-align: center;
  font-size: 2.75rem;
  text-wrap: wrap;
  width: auto;
  margin-bottom: 2rem;
  margin-top: 5rem;
}

.bottom-button{
  font-size: 1rem;
  font-weight: bold;
  color:black;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  background-color: rgb(245,245,245);
}

.bottom-button:hover{
  background-color: rgb(174, 174, 174);
  color: white;

}


.bottom-button:active{
  background-color: black;
  color: white;
}

.action-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap:1rem;
  margin-bottom: 2rem;
  padding: 1rem;
}

@media (max-width:768px) {

  .bottom-title{
    font-size: 1.75rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .signup-bottom-card{
    
    padding: 0 1.25rem;
    margin: 0 1rem;
  }


  .action-container{
    margin-bottom: 1rem;
    padding: .75rem;
  }
}