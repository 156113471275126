body{
  background-color: white;
}

header{
  
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  

}
@media (min-width: 1280px) {
  .max-col {
    max-width:1280px;
  }
}

.logo {
  height: 80px;
  margin: 10px 0 ;
  margin-left: auto;
  margin-right: auto;
  
}

@media (max-width: 800px) {
  .max-col {
    width: 100%;
  }
}

.max-col {
  width:fit-content;
  margin-left: auto;
  margin-right: auto;
}

.lead-in {
  
  display: flex;
  flex-direction: column;
  align-items:center;

  height:auto;
  padding: 100px 0px 0px 0px;
  

}

.slogan {
  flex: 1;
  vertical-align: center;
  font-size: 3rem;
  text-wrap: wrap;
  width: auto;
  
}



.smaller-slogan {
  color: #474747;
  flex:1;
  width: 60%;
  font-size:1.5rem;
  padding-top: 16px;
  max-width: 600px;
}



.section-slogan{
  flex: 1;
  vertical-align: center;
  font-size: 3rem;
  text-wrap: wrap;
  width: auto;
  margin-bottom: 2rem;
  margin-top: 7rem;
}

h1, p {
  margin:0;
  text-align: center;
}


.demo-img {
  width:100%;
  
}

img {
  max-width: 100%;
  height:auto;
}


.details-section{
  height: auto;
  
}

.details-flex-group{
  /*background-color: brown;
  display: flex;
  justify-content: space-around;
  align-items: center;*/
  display: flex;
  flex-wrap: wrap;
  gap:2rem;
  margin: 0;
  padding: 1rem;
  
}

.detail-card {
  /*width: fit-content;
  height: 200px;
  background-color: yellow;
  display: flex;
  flex-direction: column;
  align-items: center;*/
  width:100%;
  background-color: rgb(245,245,245);
  
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
}

@media (min-width:768px) {
  .detail-card {
      width:calc((100% - 4.375rem) / 3);
      /*4.375 is double the gap value plus border width sum*/
  }
}

@media (max-width:768px) {
  .detail-card {
      margin:1.5rem;
      /*4 is double the gap value*/
  }
}


.detail-img-container {
  width:90%;
  height:300px;
  margin: 2rem 0;


  display: flex;
  align-items: center;
  justify-content: center;



}
.detail-img{
  max-width: 100%;
  max-height:100%;
  border-radius: 1rem;
  
}

.detail-text{
  word-wrap: break-word;
  padding: 2rem 2rem;
  font-size: 1.75rem;
  color: black;
}

.spacer{
  margin-top: 10px;
}


.features-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
}

.features{
  width:fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  
  
}

.feature-container{
  width: 100%;
  background-color: rgb(245,245,245);
  margin: 20px 0;
  display: flex;
  padding: 1rem;
  border-radius: 1rem;
}


.feature-icon-container{
  
  border-radius: 50px;
  background-color: #239AF5;
  height:100px;
  width:100px;
  min-width: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-text-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}

.feature-icon{
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.feature-text{
  font-size: 1.75rem;
}


@media (max-width:768px) {

  .lead-in{
    padding: 40px 0px 0px 0px;
  }
  .slogan{
    font-size: 2.25rem;
  }

  .smaller-slogan{
    width: 90%;
    font-size:1.25rem;
  }

  .section-slogan{
    
    font-size: 2.25rem;
    margin-top: 3.5rem;
    margin-bottom: 1rem;
  }

  .detail-img-container {
    height:250px;
    margin: 1rem 0;  
  }
  
  
.detail-text{
  padding: 1rem;
  font-size: 1.25rem;
}


.feature-text{
  font-size: 1.25rem;
}

.features{
  margin: 0 1.5rem;
}

.feature-icon-container{
  
  
  height:80px;
  width:80px;
  min-width: 80px;

}

}