.lets-try{
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.dev-warning{
 font-size: small;
 color: #474747;
 margin-bottom: 4px;
}

.form-wrapper{
  margin-bottom: 50px;
  padding: 0 2rem;
  max-width: 95%;
}

.valid-popup{
  margin-top: 50px;
}

.signup-wrapper {
  display: flex;
}


.signup-input {
  height: 50px;
  max-width:270px;
  width: 270px;
  padding: 0;
  border-style: solid none solid solid;
  border-width: 2px;
  border-radius: 50px 0 0 50px;
  padding: 0 30px 0 20px;
  
  border-color: #239AF5;
  /*width:270px;  */
  font-size: 1rem;

}
.signup-input:focus {
  outline: none; /* Remove the default outline */
  box-shadow: inset 0 0 0 1px #1a74b9; /* Add an inner shadow */
}





.signup-button {
  height: 54px;
  border: solid;
  border-width: 2px;
  border-radius: 50px;
  border-color: #239AF5;
  background-color: rgb(245,245,245);
  padding: 0 20px;
  width:auto;
  transition-duration: 0.2s;
  font-size: 20px;
  white-space: nowrap;
  margin-left: -30px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color:black;
}


.signup-button:hover{
  background-color: #239AF5;
  color: white;
}

.signup-button:active{
  background-color: #1a74b9;
  color: white;
}

.error-msg{
  color: red;
  font-size: small;
  
  margin-top: 4px;
}

.after-submit {
  margin-top: 19px;
  max-width: 470px;
  width: 100%;

  height: 54px;


  margin-bottom: 54px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  color:  #239AF5;
  font-weight: bold;
  font-size: 1.25rem;
  text-align : center;
}


@media (max-width:768px) {
  .signup-input{
      height: 40px;
      width: 60%;
  }

  .signup-button{
    height: 44px;
    font-size: 16px;
    font-weight: medium;
  }

  .form-wrapper{
    margin-bottom: 30px;
  }


  
}